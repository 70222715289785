/** @jsx jsx */
import { jsx } from "theme-ui"
import { ContentWrapper, Main } from "gatsby-theme-elements"

import Archive from "../components/Archive"
import SEO from "../components/Seo"
import * as phraseURLs from "../assets/icons/phrasebook"
import { phraseMenu } from "../components/Layout/menus"

const PhrasebookArchive = () => (
  <ContentWrapper maxWidth="100%">
    <SEO title="Phrasebook" />
    <Main>
      <Archive type="Phrases" menu={phraseMenu} images={phraseURLs} />
    </Main>
  </ContentWrapper>
)

export default PhrasebookArchive
